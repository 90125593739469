<template>
    <div>
        <div class="login-container">
            <lbrx-welcome-interface></lbrx-welcome-interface>
            <div class="form-footer-container">
                <lbrx-login-logo></lbrx-login-logo>
                <div class="login-form-container">
                    <div style="width: 80%">
                        <div v-if="errorLogin"  class="error-container">
                            <lbrx-error-notification :label="error" color="#FF5757" background="rgba(255, 158, 152, 0.5)" height="10" width="96%" font-size="0.7" icon="error-icon"></lbrx-error-notification>
                        </div>
                        <div class="form-container">
                            <lbrx-input type="email" icon="email-icon" label="Addresse E-mail" :model-value="email" v-model="email"></lbrx-input>
                            <lbrx-input type="password" icon="password-icon" label="Mot de passe" :model-value="password" v-model="password"></lbrx-input>
                            <div style="padding-left: 0 !important;height: 7vmin;margin-top: -2vmin;margin-bottom: 2vmin">
                                <lbrx-checkbox :model-value="rememberMe" v-model="rememberMe" label="Se souvenir de moi" ></lbrx-checkbox>
                            </div>
                            <lbrx-button v-model="sending" icon="login-icon" @clicked="login()" label="Se connecter" color="#FFFFFF" background="#008FCA" height="9vmin" width="100%" font-size="1.1"></lbrx-button>
                        </div>
                    </div>
                </div>
                <div class="login-footer">
                    <div class="footer-container">
                        <div class="copyright">
                            liberrex.com © Copyright 2023
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LbrxErrorNotification from "@/components/LbrxErrorNotification.vue";
import LbrxLoginLogo from "@/components/LbrxLoginLogo.vue";
import LbrxWelcomeInterface from "@/components/LbrxWelcomeInterface.vue";
import LbrxInput from "@/components/LbrxInput.vue";
import LbrxCheckbox from "@/components/LbrxCheckbox.vue";
import LbrxButton from "@/components/LbrxButton.vue";

export default {
  name: 'HelloWorld',
    components: {LbrxButton, LbrxCheckbox, LbrxInput, LbrxWelcomeInterface, LbrxLoginLogo, LbrxErrorNotification},
  props: {
    msg: String
  },
    data() {
      return {
          email:"",
          password: "",
          tokenUpdated:false,
          sending:false,
          rememberMe:false,
          error:'',
          errorLogin:false,
          callerUrl:"",
      }
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        const routeData = urlParams.get('data');
        const data = JSON.parse(decodeURIComponent(routeData));
        this.callerUrl = data.url;
    },
    methods : {
    login() {
        this.sending = true;
          axios.post("https://api-micro.liberrex.com/api/login", {"email": this.email, "password": this.password})
          .then((res)=>{
            window.opener.postMessage(res.data.access_token, this.callerUrl);
              window.close();
          })
          .catch((err)=>{
              console.log(err);
          })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 1200px) {
    .form-footer-container {
        justify-content: center !important;
        width: 100% !important;
    }
}
.error-container{
    display: flex;
    justify-content: center;
    margin-bottom: 2vmin;
}
.footer-container{
    text-align: center;
    width:50%;
}
.login-footer {
    width: 100%;
    display: flex;
    justify-content: center;
}
.login-form-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin: auto;
}

.login-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: auto !important;
}
.form-footer-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: space-between;
}
</style>
