<template>
<div class="button-container">
    <div class="form-check checkbox-container">
        <input id="lbxCheckbox" class="input-style" type="checkbox" :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked)"/>
        <label class="form-check-label" for="lbxCheckbox">
            {{label}}
        </label>
    </div>
</div>
</template>
<script>
export default {
    name: 'LbrxCheckbox',
    props: {
      label:{
          required: true,
          value: String
      },
      modelValue:Boolean,
    },
    emits: ['update:modelValue'],
}
</script>

<style scoped>
.form-check-label{
    font-family: 'Exo 2',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 3vmin;
    padding-top: 0.3vmin;
    line-height: 4vmin;
    /* identical to box height */
    /* Medium Gray */

    color: #8A8A8A;
}
.checkbox-container{
    height: 1vmin;
    display: flex;
    padding-left: 0 !important;
}
input[type=checkbox]
{
    cursor: pointer;
    -webkit-appearance: none;
    width: 4.3vmin;
    height: 4.3vmin;
    border: 0.1vmin solid lightgray;
}
input[type=checkbox]:checked {
    background-color: #008FCA;
}
.button-container {
    margin: 2vmin 2vmin 0 0vmin;
    width: 100% !important;
    text-align: left;
    height: 7vmin;
}
.input-style{
    border: 1px solid #E4E4E4;
    margin-right: 4vmin;
}
</style>