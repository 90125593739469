<template>
<div v-if="activeFeature">
<div class="features-container">
    “ {{activeFeature.text}} “
   <div class="signature-container">
        <div class="signature">
            {{ activeFeature.signature }}<br>{{activeFeature.position}}
        </div>
   </div>
</div>
    <div class="pointers">
        <div v-for="feature in features" :key="feature.id" class="pointer" :class="{'active-pointer': feature.id === activeFeature.id}"></div>
    </div>
</div>
</template>
<script>
export default {
    name: 'LbrxHomeFeatures',
    data() {
        return {
            features : [
                {
                    id:1,
                    text:"Nous sommes ravis de vous présenter notre solution de gestion de file d'attente innovante. Simplifiez votre expérience de file d'attente et améliorez l'efficacité de votre entreprise dès aujourd'hui.",
                    signature: "Achraf Ammar",
                    position: "CEO - Liberrex"
                },
                {
                    id:2,
                    text:"Salem alaykom nesi w ahbebi jemla w bel hamla",
                    signature: "Med Amine Khaili",
                    position:" Fullstack developer - Liberrex"
                },
                ],
            activeFeature: null,
        }
    },
    created() {
        this.activeFeature= this.features[0];
        setInterval(()=>{
                let index = this.features.indexOf(this.activeFeature)+1;
                if(index === this.features.length)
                    index = 0;
                this.activeFeature = this.features[index];
        },5000)
    }
}
</script>

<style scoped>

.features-container {
    width: 88% !important;
    min-height: 350px !important;
    padding: 80px 40px 40px;
    background: linear-gradient(100.84deg, rgba(255, 255, 255, 0.183) 0%, rgba(255, 255, 255, 0.111) 100%);
    font-family: 'Square Peg';
    font-style: normal;
    font-weight: 400;
    font-size: calc(1.7*(1vh + 1vw));
    line-height: 4.4vmin;

    color: #FFFFFF;
}
.signature {
    font-family: 'Square Peg',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: calc(1.4*(1vh + 1vw));
    line-height: 4vmin;
    color: #FFFFFF;
}
.signature-container {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 10%;
}
</style>