<template>
    <div class="button-container">
        <button :disabled="modelValue" class="lbrx-button justify-content-between" @click="$emit('clicked')" :style="{'border': border ? border : 'none','opacity': modelValue ? '0.6': '1','display': small ? 'flex': '', 'flex-direction': small ? 'column': '','background':background, 'color':color, 'height': height,'width': width, 'font-size': 'calc('+fontSize+'*(1vh + 1vw))'}">
            <img style="float: left;margin-left: 5vmin;margin-right: -5vmin" v-if="icon && !small" class="icon-style" :src="getIcon()"/>
            <div  style="width: 100%;display: flex;justify-content: center;" :style="{'padding-top': label ? '0.7vmin' :'3vmin'}" v-if="icon && small">
                <img :style="{'height': label ? '4vmin': '7vmin'}" style="margin-right: 0 !important;" v-if="icon" class="icon-style" :src="getIcon()" alt=""/>
            </div>
            <span v-if="label && !small">{{label}}</span>
            <div style="text-align: center;width: 100%" :style="{'font-size': fontSize+'vmin'}" v-if="label && small">{{label}}</div>
        </button>
    </div>
</template>
<script>
export default {
    name: 'LbrxButton',
    props: {
        fontSize:{
            required: true,
            value: String
        },
        border:{
            required: false,
            value: String
        },
        height:{
            required: true,
            value: String
        },
        width:{
            required: true,
            value: String
        },
        background:{
            required: true,
            value: String
        },
        color:{
            required: true,
            value: String
        },
        label:{
            required: false,
            value: String
        },
        icon:{
            required: false,
            value: String
        },
        small: Boolean,
        modelValue:Boolean,
    },
    emits: ['clicked'],
    methods: {
        getIcon() {
            if(this.icon) {
            var images = require.context('../assets/')
            return images('./icons/' + this.icon + ".svg")
            }
        },
    }
}
</script>

<style scoped>
.lbrx-button{
    font-family: 'Exo 2',serif;
    font-style: normal;
    line-height: 4vmin;
    justify-content: left !important;
    border: none ;
    /* identical to box height */
}
.icon-style{
    height: 5vmin;
    margin-top: -0.1vmin;
    margin-right: 3vmin;
}
.button-container {
    margin-top:2vmin;
    margin-bottom:2vmin;
    width: 100% !important;
    display: flex;
    justify-content: center;
}

@media (max-width: 1200px) {
    .button-container {
        margin: 0 !important;
    }
}
</style>