<template>
    <div class="welcome-container">
        <h1 class="welcome-title">
            Terminal d’appel
        </h1>
        <div class="welcome-text">
            La solution qui faut pour gérer ses clients en toute tranquillité.
            Conçu pour vos faites gagner du temps et rendre votre environnement de travail plus efficace.
        </div>
        <br>
        <div class="featurnes-container">
            <LbrxHomeFeatures></LbrxHomeFeatures>
        </div>
    </div>
</template>

<script>
import LbrxHomeFeatures from "@/components/LbrxHomeFeatures.vue";

export default {
    name: 'LbrxWelcomeInterface',
    components: {LbrxHomeFeatures},
}
</script>

<style scoped>
@media (max-width: 1200px) {
    .welcome-container {
        display: none !important;
    }
}
.featurnes-container{
    width: 100%;
    margin-top: 3%;
    padding-bottom:100px ;
}
.welcome-text {
    font-family: "Exo 2", serif;
    font-style: normal;
    font-weight: 300;
    font-size: 3vmin;
    width: 92%;
    margin-top: 8vmin;
    line-height: 4vmin;

    color: #ffffff;
}
.welcome-title {
    margin-top: 10vmin;
    margin-bottom: 8vmin;
    font-family: "Exo 2", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 8vmin;
    line-height: 10vmin;
    /* identical to box height */

    color: #ffffff;
}
.welcome-container {
    padding-top: 2vmin;
    padding-left: 10vmin;
    margin: 0 auto;
    width: 60%;
    display: block;
    overflow: hidden;
    text-align: left;
    background: linear-gradient(270deg, #008fca 100%, #4bb0da 100%);
}
</style>