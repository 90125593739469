<template>
    <div class="image-container">
        <img style="width: 65%" src="../assets/images/Logo_Bleu.png">
    </div>
</template>

<script>
export default {
    name: "LbrxLoginLogo",
}
</script>
<style scoped>
@media (max-width: 1200px) {
    .image-container {
        margin-top: 15vmin !important;
    }
}
.image-container {
    margin-top: 5vmin;
    width: 100%;
    height: 14vmin;
    display: flex;
    justify-content: center;
    margin-bottom: 2vmin;
}
</style>